















import { Component, Vue } from 'vue-property-decorator';
import MainHeader from '@/shared/resources/components/MainHeader.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import DocumentsList from '@/app/modules/documents/components/DocumentsList.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';

@Component({
  components: {
    DocumentsList,
    GridRow,
    GridCol,
    MainHeader,
  },
})
export default class InvoicesView extends Vue {
}
